html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  font-family: 'Poppins';
  min-height: 100vh;
}

.App {
  text-align: center;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
  font-family: 'Poppins';
  src: url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'PoppinsBold';
  src: url(./assets/fonts/Poppins-Bold.ttf) format('truetype');
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.onboardingForm label,
.onboardingForm input,
.onboardingForm textarea,
.hostHomeForm * {
  font-family: 'Poppins'! important;
}

.onboardingForm .MuiSelect-select{
  text-align: left;
}

.MuiFormLabel-root.Mui-focused {
  color: #383839! important;
}

.quantytySelectorInput::-webkit-outer-spin-button,
.quantytySelectorInput::-webkit-inner-spin-button,
.quantytySelectorInput {
  -webkit-appearance: none! important;
  margin: 0! important;
  -moz-appearance: textfield! important;
}

.MuiDropzonePreviewList-image {
  height: unset! important;
}

.MuiDropzoneArea-root {
  min-height: unset! important;
}


.personalInfoForm .MuiDropzoneArea-root {
  min-height: 102%! important;
  min-width: 102%! important;
  border: none! important;
  background-color: lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiDropzoneArea-icon {
  display: none!important;
}

.onboardingForm .MuiDropzonePreviewList-root.MuiGrid-container {
  width: unset! important;
  margin: 0;
  padding: 15px;
}

.diagonal-container {
  background: linear-gradient(to bottom right, #fff calc(50% - 2px), #aaa, #fff calc(50% + 2px) )! important;
}

.o_day-picker .i_day-picker-row div.o_selected-day {
  background: #ffffff! important;
}

.o_day-picker .i_day-picker-header {
  display: flex;
  color: black;
  font-weight: 900;
  text-transform: capitalize;
  border-bottom: 1px solid #eaecec;
}

.o_day-picker .e_day-picker-buttons {
  justify-content: space-between;
}

.o_day-picker {
  min-width: 270px;
}

.o_selected-day, .o_selected-day * {
  pointer-events: none;
}

.onboardingForm.codes input{
  padding: 10px;
}

.MuiCircularProgress-colorPrimary {
  color: #7E2D87 !important;
}
.mobile.cancelBookingDialog .MuiDialogActions-spacing > :not(:first-child) {
  margin-left: 0! important;
  margin-bottom: 8px;
}

.cancelBookingDialog .MuiCircularProgress-colorPrimary {
  color: #FF4941 !important;
}

.hostSection .MuiCircularProgress-colorPrimary {
  color: #00D1BF !important;
}

.MuiCircularProgress-colorSecondary {
  color: white !important;
}

.securityOnboarding div.MuiDropzoneArea-root {
  border: none! important;
}

.securityOnboarding .MuiGrid-item {
  max-width: fit-content! important;
  flex-basis: unset!important;
}

.securityOnboarding .MuiDropzonePreviewList-removeButton,
.personalInfoForm .MuiDropzonePreviewList-removeButton {
  display: none;
}

.onboardingForm.personalInfoForm .MuiDropzonePreviewList-root.MuiGrid-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.onboardingForm.securityOnboarding .MuiDropzonePreviewList-root.MuiGrid-container,
.onboardingForm.personalInfoForm .MuiDropzonePreviewList-root.MuiGrid-container {
  padding: 0! important;
}

.onboardingForm.personalInfoForm .MuiDropzonePreviewList-imageContainer {
    flex-grow: unset;
    max-width: unset;
    flex-basis: unset;
}

.dashboardMenuButton .MuiButton-label {
  justify-content: unset;
}

.discountInputContainer * {
  border: none! important;
}

.idSample > * {
  position: absolute;
}

.idSample > .staticPosition {
  position: static;
}

.idSample > img {
  left: 15px;
}

.spaceEditionContainer .MuiTab-root {
  min-width: unset! important;
}

.MuiTabs-indicator {
  background-color: rgb(65, 190, 180)! important;
}

.hostSection .MuiLinearProgress-barColorPrimary {
  background-color: #00D1BF! important;
}

.MuiLinearProgress-colorPrimary {
  background-color: lightgray! important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #7E2D87! important;
}

.placesAutocomplete * {
  width: 100%;
}

.placesAutocomplete label {
  text-align: left;
}

.hostSection .MuiInput-underline:after {
  border-bottom: 2px solid #00D1BF! important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #7E2D87! important;
}

/* ONLY SAFARI BROWSER STYLES */
_::-webkit-full-page-media, _:future, 
:root .quantytySelector svg.MuiSvgIcon-root {
  margin-top: -12px! important;
}

@supports (-webkit-touch-callout: none) {
  _::-webkit-full-page-media, _:future, 
:root .quantytySelector svg.MuiSvgIcon-root {
  margin-top: 0! important;
}
}

:root .publicationHostPreview .editButton.MuiButtonBase-root,
.editSearchButton.MuiButtonBase-root {
  padding: 0;
}

.editButton.Mui-disabled {
  opacity: 0.5;
}

.hostSection .spacePhotosForm .MuiIconButton-colorPrimary {
  color: #00D1BF;
}

.hostSection .dashboardMenuButton.MuiButton-textPrimary {
  color: #00D1BF! important;
}

.hostSection .okMpButton.MuiButton-textPrimary {
  color: #00D1BF! important;
}

.servicesPriceInput input.MuiOutlinedInput-input{
  padding: 5px;
  text-align: center;
}

a.footerLink:hover {
  text-decoration: underline! important;
}

.termsAndConditions,
.privacy {
  text-align: left;
  margin: 20px;
}

.birthInput > div {
  width: 100%;
}

.birthInput label {
  text-align: left;
}

.birthInput .Mui-disabled,
.reservQty  .Mui-disabled {
  color: #383839;
}

.publicationHostPreview * {
  max-width: 90vw! important;
}

.publicationContainer div {
  color: #383839;
}

.publicationMain img.image-gallery-image {
  height: 300px;
  width: 800px;
}

button.getLocationButton:disabled {
  color:lightgray! important;
  max-width: 80vw;
}

.publicationMain .carousel .slider-wrapper.axis-horizontal .slider .slide {
  display: flex;
  justify-content: center;
}

.reserveButton span {
  color: white;
}

.publicationContainer .publicationMain .carousel-root .thumbs .thumb img {
  height: 50px! important;
}

.addedService * ,
.addedService {
  color: #818181! important;
}

.MuiCheckbox-root.Mui-checked {
  color: #7E2D87! important;
}

.hostSection .MuiCheckbox-root.Mui-checked {
  color: #00D1BF! important;
}

.Mui-focused fieldset {
  border-color: #7E2D87! important;
}

.hostSection .Mui-focused fieldset {
  border-color: #00D1BF! important;
}

.MuiRadio-root {
  color: #818181! important;
}

.hostSection .MuiRadio-root.Mui-checked {
  color: #00D1BF! important;
}

.MuiRadio-root.Mui-checked {
  color: #7E2D87! important;
}

.hostSection .MuiButton-textPrimary {
  color: #00D1BF! important;
}

.MuiButton-textPrimary {
  color: #7E2D87! important;
}

.MuiIconButton-colorSecondary:hover {
  background-color: rgba(0, 0, 0, 0.04)! important;
}

.MuiPickersPopper-root, .pac-container.pac-logo, .MuiDialog-root {
  z-index: 9999! important;
}

.MuiPickersDay-root.Mui-disabled {
  color: lightgray!important;
}

.carousel-root.mobile {
  max-width: 90vw;
}

.carousel-root.desktop {
  max-width: 100%;
}

.hostBookings > * {
  width: 100%;
}

.hostBookings {
  width: 100%;
}

.userBookings .MuiAccordion-root.Mui-disabled {
  background-color: #ffffff! important;
}

.userBookings .MuiAccordionSummary-root.Mui-disabled {
  opacity: 1;
}

.spaceReviews > div {
  width: 100%;
}

.onboardingForm .rug-card .rug-card-progress .__progress-cricle {
  stroke: #7E2D87! important;
}

.onboardingForm .rug-card-progress-count {
  text-shadow: 3px 3px 3px #000000;
}

.onboardingForm .rug .rug-items.__card {
  min-height: 0px;
  padding-top: 8px;
}

.onboardingForm .rug-items.__card.__sorting > div:nth-child(1) > div {
  border: 3px solid #00D1BF;
  border-radius: 15px;
}

.tooltipCalendar{
  background-color: #64646498!important;
  color: #fff!important;
}

.searchDateInput .MuiPickersDateRangePickerInput-root { 
  display: flex;
  justify-content: space-between;
}

.MuiPickersDateRangeDay-rangeIntervalDayHighlight div button:not(.Mui-disabled) {
  color: black;
}

.companyTimeLine .MuiDivider-middle {
  background-color: #7E2D87! important;
}